/* bootstrap fixups etc. */

.form-floating > .form-select ~ label.required,
.form-floating > .form-control:not(:placeholder-shown) ~ label.required,
.form-floating > .form-control:focus ~ label.required,
label.required {
  &::after {
    content: "*";
    color: #ff0000;
    margin-left: 3px;
    text-align: right;
    inset: unset;
  }
}

input#user_color + label {
  &::after {
    background-color: transparent;
  }
}

/* keep modal backdrops invisible */
.modal-backdrop.show {
  opacity: 0;
}

.modal-backdrop:first-child {
  opacity: 0 !important;
}

.modal .modal-shadow .modal-content {
  box-shadow: 0px 0px 20px rgba(0,0,0,0.5);
}

@include media-breakpoint-down(md) {
  .modal-fullscreen-sm-down {
    .modal-content {
      height: 100%;
      border-radius: 0;
      position:relative;
    }

    .modal-footer {
      border-radius: 0;
      bottom:0px;
      position:absolute;
      width:100%;
    }
  }
}

/* navbar should be over footer */
.sticky-top {
  z-index: 1040;
}
