/* customize variables */
:root {
  --bs-font-sans-serif: Roboto,system-ui,-apple-system,"Segoe UI","Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.navbar {
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-border-color: transparent;
}
.alert {
  --bs-alert-padding-y: 0.5rem;
  --bs-alert-padding-x: 1rem;
  --bs-alert-border: none;
}
.offcanvas {
  --bs-offcanvas-width: 100vw;
}
