#rrule {

  .rrule-visible-end-count,
  .rrule-visible-end-until,
  .rrule-visible-all,
  .rrule-visible-yearly,
  .rrule-visible-monthly,
  .rrule-visible-weekly,
  .rrule-visible-daily {
    display: none;
  }

  .rrule-end-count {
    .rrule-visible-end-count { display: inherit; }
  }

  .rrule-end-until {
    .rrule-visible-end-until { display: inherit; }
  }

  &.rrule-yearly {
    .rrule-visible-all { display: inherit; }
    .rrule-visible-yearly { display: inherit; }
  }

  &.rrule-monthly {
    .rrule-visible-all { display: inherit; }
    .rrule-visible-monthly { display: inherit; }
  }

  &.rrule-weekly {
    .rrule-visible-all { display: inherit; }
    .rrule-visible-weekly { display: inherit; }
  }

  &.rrule-daily {
    .rrule-visible-all { display: inherit; }
    .rrule-visible-daily { display: inherit; }
  }

}
