/* fullcalendar */
:root {
  --fc-event-border-color: #fff;
  --fc-xsmall-font-size: 70%;
}

:root, [data-bs-theme="light"] {
  --fc-border-table:  var(--bs-light-border-subtle);
  --fc-border-mirror: var(--bs-dark-border-subtle);
  /* fix list mode colors */
  --fc-custom-heading-bg: var(--bs-gray-200);
  --fc-list-event-hover-bg-color: var(--bs-gray-100);
}

[data-bs-theme="dark"] {
  --fc-border-table:  var(--bs-dark-border-subtle);
  --fc-border-mirror: var(--bs-light-border-subtle);
  /* fix list mode colors */
  --fc-custom-heading-bg: var(--bs-light-border-subtle);
  --fc-list-event-hover-bg-color: var(--bs-gray-800);
}

thing-calendar {
  display: block;

  &.slot-duration-60 {
    .fc .fc-timegrid-slot {
      border-bottom: 0px;
      height: 3em;
    }
  }

  /* fix list mode colors */
  /* fix day heading */
  .fc-list-table {
    .fc-theme-bootstrap5-shaded {
      background-color: var(--fc-custom-heading-bg);
    }
    .fc-list-day {
      z-index: 999;
      position: relative;
      .fc-list-day-cushion {
	font-weight: var(--bs-body-font-weight);
      }
    }
  }
}

.fc-view.fc-timegrid {
  .fc-event {
    .event-type.badge {
      --bs-bg-opacity: 1;
      background-color: rgba(248, 249, 250, 1) !important;
      background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
      --bs-text-opacity: 1;
      background-color: #fff !important;
      color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
    }
  }
}

.fc {
  .fc-timegrid-divider {
    padding: 0px;
  }
  .fc-daygrid-block-event .fc-event-title {
    .event-member,
    .event-type,
    .event-text,
    .event-text p {
      display: inline-block;
    }
    br {
      display: none;
    }
    .event-text {
      margin-left: 5px;
      &:before {
	content: " – ";
      }
    }
  }

  .fc-event-title-container {
    .user-label {
      white-space: nowrap;
    }
  }

  .fc-daygrid-dot-event .fc-event-title {
    font-weight: normal;
  }

  .fc-event .fc-event-title p {
    margin-bottom: 0rem;
    font-weight: normal;
  }

  .fc-list-event-dot {
    display: none;
  }

  /* fix month view background */
  .fc-timegrid-event,
  .fc-daygrid-event,
  .fc-h-event {
    display: block;
    margin-bottom: 1px;
    padding-left: 3px;
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
  }

  .fc-timegrid-event .fc-event-time,
  .fc-daygrid-event .fc-event-time {
    margin-right: 0px;
  }

  .fc-timegrid-event,
  .fc-daygrid-event {
    border-radius: 0px;
  }

  /* custom border for member color */
  .fc-h-event,
  .fc-v-event {
    border: 0px solid var(--fc-event-border-color);
    border-left-width: 6px;
    padding-left: 0px;

    > .fc-event-main {
      border-left: 1px solid #fff;
    }
  }

  .fc-timegrid-event .fc-event-main,
  .fc-daygrid-event .fc-event-main,
  .fc-h-event .fc-event-main {
    color: unset;
    /* var(--fc-event-text-color); */
  }

  .fc-col-header-cell {
    text-align: left;
    padding-left: 1em;
  }
  .fc-day .fc-col-header-cell-cushion {
    /* day display */
    font-size: var(--fc-small-font-size);
    color: var(--bs-secondary);
    text-align: left;
  }
  .fc-day-today {
    background-color: var(--bs-tertiary-bg);
  }
  .fc-day-today .fc-col-header-cell-cushion {
    color: var(--bs-primary);
  }
  .fc-custom-date {
    font-weight: bold;
    font-size: 125%;
  }


  .fc-daygrid-body .fc-timegrid-axis-cushion {
    /* all day first column */
    font-size: var(--fc-xsmall-font-size);
  }
  .fc-col-header .fc-timegrid-axis-cushion,
  .fc-timegrid-slot-label-cushion {
    /* week number */
    /* "10:00" time label in first column */
    font-size: var(--fc-small-font-size);
  }

  a.fc-daygrid-day-number {
    text-decoration: none;
    font-size: var(--fc-small-font-size);
    color: var(--fc-neutral-text-color);
    font-weight: bold;
  }

  .fc-daygrid-week-number {
    text-decoration: none;
    font-size: var(--fc-small-font-size);
  }

  th.fc-timegrid-axis a,
  th.fc-day a {
    text-decoration: none;
    color: var(--bs-body-color);
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: normal;
  }

  /* fullcalendar bugfix width overflows in month view */
  .fc-event-main-frame {
    max-width: 100%;

    /* FIXME: make configurable? */
    /* .fc-event-time { */
    /*   display: none; */
    /* } */
  }
}

@include media-breakpoint-down(lg) {
  .fc .fc-view-harness {
    margin-left: -10px;
    margin-right: -10px;
  }
  .fc .fc-timeGridDay-view .fc-custom-weekday-short {
    display: none !important;
  }
  .fc .fc-timeGridDay-view .fc-custom-weekday-long {
    display: block !important;
  }
  .fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
    text-align: center;
    padding-left: 0;
  }
  .fc-toolbar.fc-header-toolbar {
    display: block;
    /* flex-direction: column; */

    font-size: 70%;

    .fc-listWeek-button {
      display: none;
    }
    .fc-toolbar-chunk:nth-child(1) {
      /* border: 3px solid #f00; */
    }
    .fc-toolbar-chunk:nth-child(2) {
      display: none;
    }
    .fc-toolbar-chunk:nth-child(3) {
      /* border: 3px solid #f00; */
      justify-content: space-between;
      /* border: 3px solid #f00; */
    }
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.fc-toolbar {
  flex-wrap: wrap;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fc .fc-toolbar-title {
  white-space: nowrap;
  font-size: calc(1.275rem + 0.3vw);
}

/* drag gestures */
/* https://stackoverflow.com/questions/41998204/interact-js-drag-not-working-on-chrome-mobile-emulator */
.fc-col-header,
.fc-col-header * {
  touch-action: none;
  user-select: none;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid var(--fc-border-table);
}

.fc .fc-event-mirror-dragging .fc-event-mirror {
  z-index: 9999;
  border: 1px #000 dashed !important;
  background-color: transparent;
  .fc-event-main {
    border-left: 0 !important;
  }
  .fc-event-time {
    display: block;
    transform: translate(-20px, -2.5em);
    font-size: 1em;
    min-height: 1.6em;
    position: absolute;
    background: var(--fc-border-mirror);
    color: #fff;
    border-radius: 3em;
    padding: .2em 5px;

    &::after {
      content: "" !important;
    }
  }
}

/* current day */
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-timegrid-col.fc-day-today {
  background-color: var(--bs-tertiary-bg);
  /* border: 1px dashed var(--bs-primary); */
  /* border: 1px dashed var(--bs-primary); */
}
