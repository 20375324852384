@import '~@fortawesome/fontawesome-free/scss/fontawesome';
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

/* FIXME: reorganize */
$form-floating-label-height: 1.25em;
@import "~bootstrap/scss/bootstrap.scss";
@import "bootstrap-config";
@import "bootstrap-custom";

@import "fullcalendar-custom";
/* https://animate.style/ */
@import "animate.css";

@import "rrule";

.user-label > span {
  width:0.75em;
  height: 0.75em;
}

.turbo-progress-bar {
  height: 5px;
  background-color: green;
}

.alert {
  position: absolute;
  top: 0;
  z-index: 9999;
  margin-bottom: 0;
  margin: 0 auto;
  width: 40vw; /* FIXME: mobile view... */
  left: 30vw;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.thing-card {
  .card-text {
    max-height: calc((100vh - 160px) * 0.6 - 150px);
    min-height: 8em;
    text-overflow: ellipsis;
    overflow: auto;
    font-size: 90%;
    margin-bottom: 10px;
  }
}

.members-card {
  .card-text {
    max-height: calc((100vh - 160px) * 0.4 - 150px);
    min-height: 2em;
    text-overflow: ellipsis;
    overflow: auto;
    font-size: 90%;
  }
}

/* password-visibility */
.password-visibility-icon {
  right: $grid-gutter-width;
  position:absolute;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
}
